import React from 'react';

import {
    Link
  } from "react-router-dom";

import './Header.css';

function Header() {
  return (
    <div className="header_main">
        <div className="header_main_bar">
            <div className="header_main_bar_title">
                davemac.dev
            </div>
            <nav className="header_main_bar_menu">
                <Link to="/" className="header_main_bar_menu_item">Home</Link>
                <Link to="/about" className="header_main_bar_menu_item">About</Link>
                <Link to="/contact" className="header_main_bar_menu_item">Contact</Link>
            </nav>
        </div>
    </div>
  );
}

export default Header;