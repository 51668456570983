import React from 'react';
import './Content.css';

type ContentProps = {
    children: React.ReactNode;
}

function Content(props: ContentProps) {
    return (
        <div className="content_main">
            <div className="content_main_inner">
                {props.children}
            </div>
        </div>
    );
}

export default Content;