import React from 'react';
import Content from '../Content/Content';

function Contact() {
    return (
        <Content>
            Contact
        </Content>
    );
}

export default Contact;