import React from 'react';
import Card from '../Card/Card';
import CardList from '../CardList/CardList';
import Content from '../Content/Content';
import Splash from '../Splash/Splash';

const codeImage = require('../images/code.webp');
const phoneImage = require('../images/phone.webp');

function Home() {
    return (
        <Content>
            <Splash />
            <CardList>
                <Card imgSrc={codeImage} urlSrc="/About" title="About" />
                <Card imgSrc={phoneImage} urlSrc="/Contact" title="Contact" />
            </CardList>
        </Content>
    );
}

export default Home;