// import React, { useEffect, useState } from 'react';
import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import About from '../About/About';
import Contact from '../Contact/Contact';
import Home from '../Home/Home';

import Header from '../Header/Header';
// import { ITest } from '../_Code/Interfaces/ITest';
// import { api } from '../_Code/service';
import './App.css';
// import Content from '../Content/Content';

function App() {
  // const [error, setError]: any = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  // const [items, setItems] = useState([] as ITest[]);

  // useEffect(() => {
  //   api<ITest[]>("https://localhost:44347/api/test")
  //     .then(response => {
  //       setIsLoaded(true);
  //       setItems(response);
  //     })
  // }, []);

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }
  // else if (!isLoaded) {
  //   return <div>Loading...</div>;
  // }
  // else {
    return (
      <div className="App">
        <Router>
          <Header />
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
          {/* <ul>
            {items.map(item => (
              <li key={item.id}>
                {item.id} {item.description}
              </li>
            ))}
          </ul> */}
      </div>
    );
  }
// }

export default App;
