import React from 'react';
import './CardList.css';

type CardListProps = {
    children: React.ReactNode;
}

function CardList(props: CardListProps) {
    return (
        <div className="cards-list">
            {props.children}
        </div>
    );
}

export default CardList;