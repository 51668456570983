import React from 'react';
import './Splash.css';

function Splash() {
    return (
        <div className="splash">
            <h1>
                davemac.dev
            </h1>
            <p className="splash_text">
                Hi, I'm Dave McCormick, a full stack developer from Melbourne, Australia.
                For over a decade, I have been delivering software using the best tools for the job.
            </p>
            <p className="splash_text">
                Whether it's designing interactive web-based experiences, 
                creating APIs and server-based features,
                or building CI/CD pipelines,
                I love getting involved, learning new things and producing high quality solutions.
            </p>
        </div>
    );
}

export default Splash;