import React from 'react';
import './Card.css';

type CardProps = {
    urlSrc: string;
    imgSrc: string;
    title: string;
}

function Card(props: CardProps) {
    return (
        <a className="card" href={props.urlSrc}>
            <div className="card_image"> <img src={props.imgSrc} alt={props.title} /> </div>
            <div className="card_title title-white">
                <p>{props.title}</p>
            </div>
        </a>
    );
}

export default Card;