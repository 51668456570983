import React from 'react';
import Content from '../Content/Content';

function About() {
    return (
        <Content>
            Megan is the most awesome Megan I know! Yeah!
        </Content>
    );
}

export default About;